export function getEnumKeyByValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue?: string
): keyof T | undefined {
  const cleanedEnumValue = enumValue?.toLowerCase().replace(/\s/g, '')

  const keys = Object.keys(myEnum).filter((x) => {
    const cleanedMyEnumValue = myEnum[x as keyof T].toLowerCase().replace(/\s/g, '')
    return cleanedMyEnumValue === cleanedEnumValue
  })

  return keys.length > 0 ? (keys[0] as keyof T) : undefined
}
