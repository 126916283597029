import { SortObject } from '@/core/typings'

export const parseSortObject = (obj?: SortObject) => {
  if (!obj) return 'id'
  const x = Object.keys(obj).map((key) => {
    const value = obj[key]
    return value < 0 ? `-${key}` : key
  })

  return x.join(' ')
}
