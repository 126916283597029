import {
  AllowedPrices,
  AllowedSizes,
  FilterRealEstateParams,
  RealEstateVariant
} from '@/core/real-estate/typings'
import { propIfExists } from '@/utils/prop-if-exists'
import { getEnumKeyByValue } from '@/utils/get-enum-key-by-value'

export function createInitialFilters(
  searchParams: Record<string, string | string[]>
): FilterRealEstateParams | null {
  if (searchParams && Object.keys(searchParams).length === 0) {
    return null
  }

  const maxDistance = searchParams?.maxDistance ? Number(searchParams.maxDistance) : undefined
  const lng = searchParams?.lng ? Number(searchParams.lng) : undefined
  const lat = searchParams?.lat ? Number(searchParams.lat) : undefined
  const type = searchParams?.type
  const variants = searchParams?.variants
    ? ((searchParams.variants as string | undefined)?.split(',') as RealEstateVariant[])
    : undefined
  const sizeMtsMin = searchParams?.sizeMtsMin ? Number(searchParams.sizeMtsMin) : undefined
  const sizeMtsMax = searchParams?.sizeMtsMax ? Number(searchParams.sizeMtsMax) : undefined
  const priceMin = searchParams?.priceMin ? Number(searchParams.priceMin) : undefined
  const priceMax = searchParams?.priceMax ? Number(searchParams.priceMax) : undefined

  if (!maxDistance || !lng || !lat) {
    return null
  }

  return {
    maxDistance: maxDistance,
    coordinates: [lng, lat],
    ...propIfExists('contractType', type),
    ...propIfExists('variants', variants),
    size: {
      mtsMin: parseInt(
        AllowedSizes[getEnumKeyByValue(AllowedSizes, sizeMtsMin?.toString()) || '0']
      ),
      mtsMax: parseInt(AllowedSizes[getEnumKeyByValue(AllowedSizes, sizeMtsMax?.toString()) || '0'])
    },
    price: {
      min: parseInt(AllowedPrices[getEnumKeyByValue(AllowedPrices, priceMin?.toString()) || '0']),
      max: parseInt(AllowedPrices[getEnumKeyByValue(AllowedPrices, priceMax?.toString()) || '0'])
    }
  }
}
