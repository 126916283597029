import { FC } from 'react'
import { Divider, StackProps, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { RealEstateLightDTO } from '@/core/real-estate/typings'
import { pushIfConditionMet } from '@/utils/prop-if-exists'

interface RealEstatePriceProps extends StackProps {
  data: RealEstateLightDTO['price']
}

const RealEstatePrice: FC<RealEstatePriceProps> = ({ data, ...props }) => {
  const price = pushIfConditionMet([
    { item: { value: data.rent?.price, label: 'Renta' }, condition: !!data.rent?.price },
    { item: { value: data.sale?.price, label: 'Venta' }, condition: !!data.sale?.price }
  ])

  const filteredPrices = price.filter((p) => p.value)

  return (
    <Wrap gap={2} {...props}>
      {filteredPrices.map((item, index) => (
        <WrapItem key={index}>
          <Text fontSize="sm">
            {item.value} m2 ({item.label})
          </Text>
          {index !== filteredPrices.length - 1 && <Divider ml={2} orientation="vertical" />}
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default RealEstatePrice
