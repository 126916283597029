import React from 'react'
import { Checkbox, GridItem, HStack, SimpleGrid, Text } from '@chakra-ui/react'

type CheckboxOptionsProps<T> = {
  options: CheckboxOption<T>[]
  selected: string[]
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type CheckboxOption<T> = { value: keyof T; label: string }

const CheckboxOptions = <T,>({ options, selected, onSelect }: CheckboxOptionsProps<T>) => {
  return (
    <SimpleGrid columns={2} gap={4} w="full" p={2}>
      {options.map((option) => (
        <GridItem key={option.value.toString()}>
          <HStack spacing={1}>
            <Checkbox
              alignItems="center"
              borderColor="primary"
              colorScheme="primary"
              display="flex"
              isChecked={selected.includes(option.value.toString())}
              justifyContent="center"
              name={option.value.toString()}
              onChange={onSelect}
              size="lg">
              <Text fontSize="sm">{option.label}</Text>
            </Checkbox>
          </HStack>
        </GridItem>
      ))}
    </SimpleGrid>
  )
}

export default CheckboxOptions
