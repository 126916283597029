import type { ChangeEvent } from 'react'
import { useState } from 'react'

const useCheckboxes = (initial: string[] = []) => {
  const [selected, setSelected] = useState<string[]>(initial)

  const handleStringCheckBoxes = (event: ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name
    const isChecked = event.target.checked

    if (isChecked) {
      if (!selected.includes(checkboxName)) {
        setSelected((prevState) => [...prevState, checkboxName])
      }
    } else {
      if (selected.includes(checkboxName)) {
        setSelected((prevState) => prevState.filter((e) => e !== checkboxName))
      }
    }
  }

  const resetSelected = () => setSelected([])

  return {
    handleStringCheckBoxes,
    selected,
    resetSelected
  }
}

export default useCheckboxes
