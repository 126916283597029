import { useCallback, useState } from 'react'

const useOnScreen = ({ root = null, rootMargin = '0px', threshold = 0 } = {}) => {
  const [observer, setObserver] = useState<IntersectionObserver | undefined>()
  const [isIntersecting, setIsIntersecting] = useState(false)

  const ref = useCallback(
    (node: any) => {
      if (node) {
        const o = new IntersectionObserver(
          ([entry]) => {
            setIsIntersecting(entry.isIntersecting)
          },
          { root, rootMargin, threshold }
        )

        o.observe(node)
        setObserver(o)
      }
    },
    [root, rootMargin, threshold]
  )

  return { ref, isIntersecting, observer }
}

export default useOnScreen
