'use client'
import * as React from 'react'
import { useRef, useState } from 'react'
import {
  Box,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useOutsideClick,
  VStack
} from '@chakra-ui/react'
import { Action, ReducerActionType } from '@/hooks/useSetReset'
import { Dropdown } from '@/app/components/common/Dropdown'
import Popover from '@/app/components/common/Popover'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { formatNumber } from '@/utils/format-number'
import { AllowedSizes } from '@/core/real-estate/typings'

interface SizeFilterProps extends InputProps {
  sizeFilter: { mtsMin: AllowedSizes; mtsMax: AllowedSizes }
  dispatch: React.Dispatch<Action<{ mtsMin: AllowedSizes; mtsMax: AllowedSizes }>>
  onUpdate?: (values: (keyof AllowedSizes)[]) => void
}

export const SizeFilter = ({ sizeFilter, dispatch, flex, ...props }: SizeFilterProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const setMtsMin = (value: AllowedSizes) => {
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...sizeFilter, mtsMin: value }
    })
  }

  const setMtsMax = (value: AllowedSizes) => {
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...sizeFilter, mtsMax: value }
    })
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const [isMinOpen, setIsMinOpen] = useState(false)
  const [isMaxOpen, setIsMaxOpen] = useState(false)

  useOutsideClick({
    ref: ref,
    handler: () => {
      if (isMinOpen || isMaxOpen) return

      onClose()
    }
  })

  return (
    <Box flex={flex}>
      <Popover
        contentProps={{
          maxWidth: 'calc(100vw - var(--chakra-space-12))',
          width: 'auto',
          minW: 'var(--chakra-sizes-xs)',
          sx: {
            '.chakra-popover__body': {
              p: 0
            }
          }
        }}
        placement="bottom-start"
        {...{ isOpen }}
        target={
          <InputGroup>
            <Input
              ref={ref}
              cursor="default"
              onClick={onToggle}
              readOnly
              value={`${
                formatNumber(parseInt(sizeFilter.mtsMin)) !== '0'
                  ? `${formatNumber(parseInt(sizeFilter.mtsMin))} m2`
                  : 's/min'
              } - ${
                formatNumber(parseInt(sizeFilter.mtsMax)) !== '0'
                  ? `${formatNumber(parseInt(sizeFilter.mtsMax))} m2`
                  : 's/max'
              }`}
              {...props}
            />
            <InputRightElement cursor="pointer" onClick={onToggle}>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </InputRightElement>
          </InputGroup>
        }
        title="">
        <VStack py={2} px={4} w="full">
          <Heading w="full" as="h6" fontSize="lg">
            Tamaño
          </Heading>
          <HStack w="full" spacing="3">
            <Dropdown
              onToggle={setIsMinOpen}
              formatOption={(_key, value) => {
                const formattedNumber = formatNumber(parseInt(value))
                if (formattedNumber !== '0') {
                  return `${formattedNumber} m2`
                } else {
                  return 's/min'
                }
              }}
              flex={0.5}
              placeholder="min"
              enum={AllowedSizes}
              onOptionSelection={(e) => {
                if (e) {
                  setMtsMin(AllowedSizes[e])
                }
              }}
            />
            <Dropdown
              onToggle={setIsMaxOpen}
              formatOption={(_key, value) => {
                const formattedNumber = formatNumber(parseInt(value))
                if (formattedNumber !== '0') {
                  return `${formattedNumber} m2`
                } else {
                  return 's/max'
                }
              }}
              flex={0.5}
              placeholder="max"
              enum={AllowedSizes}
              onOptionSelection={(e) => {
                if (e) {
                  setMtsMax(AllowedSizes[e])
                }
              }}
            />
          </HStack>
        </VStack>
      </Popover>
    </Box>
  )
}
