'use client'
import React, { FC } from 'react'
import {
  Button,
  Container,
  Flex,
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import ImageCarousel from '@/app/components/common/ImageCarousel'
import { mainMediasFactory } from '@/core/media/utils/main-medias-factory'
import Pagination from '@/app/components/Pagination'
import {
  PageSizeOptions,
  RealEstateLightDTO,
  RealEstateSortOptions
} from '@/core/real-estate/typings'
import RealEstateListItem from '@/app/(public)/components/RealEstateListItem'
import { FaSquare } from 'react-icons/fa'
import { SEARCH_ITEM_SIZE } from '@/constants'
import RealEstateItemSkeleton from '@/app/(public)/components/RealEstateItemSkeleton'
import ToggleListView from '@/app/components/common/ToggleListView'
import { Dropdown } from '@/app/components/common/Dropdown'
import ShareLinkModal from '@/app/components/modals/ShareLinkModal'
import { useRouter, useSearchParams } from 'next/navigation'
import RealEstateItemVertical from '@/app/(public)/components/RealEstateItemVertical'
import { ScrollDragContainer } from '@/app/(public)/components/ScrollDragContainer'

interface RealEstateListProps {
  items: RealEstateLightDTO[] | null
  showMap?: boolean
  totalItems: number
  totalPages: number
  page: number
  setPage: (page: number) => void
  isLoading?: boolean
  isMobile?: boolean
  onReset: () => void
  onSort: (value: keyof typeof RealEstateSortOptions | null) => void
  onPageSize: (value: keyof typeof PageSizeOptions | null) => void
}

const RealEstateList: FC<RealEstateListProps> = ({
  items,
  totalItems,
  totalPages,
  page,
  setPage,
  isLoading,
  onReset,
  onSort,
  onPageSize,
  showMap,
  isMobile
}) => {
  const {
    isOpen: showList,
    onOpen,
    onClose
  } = useDisclosure({
    defaultIsOpen: !isMobile
  })

  const [currentSize, setCurrentSize] = React.useState<keyof typeof PageSizeOptions | null>(null)

  const updateSize = (value: keyof typeof PageSizeOptions | null) => {
    setCurrentSize(value ? value : null)
    onPageSize(value)
  }

  const [currentOrder, setCurrentOrder] = React.useState<keyof typeof RealEstateSortOptions | null>(
    null
  )

  const updateOrder = (value: keyof typeof RealEstateSortOptions | null) => {
    setCurrentOrder(value ? value : null)
    onSort(value)
  }

  const searchParams = useSearchParams()
  const router = useRouter()

  const handleItemClick = (item: RealEstateLightDTO) => {
    const searchQueries = searchParams.toString()
    router.push(`/propiedades/${item.id}${searchQueries ? '?' + searchQueries : ''}`)
  }

  return (
    <Container
      as={VStack}
      maxW="container.xl"
      position="relative"
      minH="100%"
      w="full"
      p={4}
      flex={1}
      align="flex-start">
      <Stack flexDirection={{ base: 'column' }} align="center" justify="flex-end" w="full">
        <HStack w="full">
          <Text minW={150} textAlign="left" fontSize="md" fontWeight="bold">
            {totalItems} propiedades
          </Text>
          {!isMobile && (
            <>
              <Spacer flex={1} />
              <ToggleListView
                isDisabled={isLoading}
                onUpdate={(v) => {
                  switch (v) {
                    case 'list':
                      onOpen()
                      break
                    case 'grid':
                      onClose()
                      break
                    default:
                      break
                  }
                }}
              />
            </>
          )}
        </HStack>
        <Flex overflow="hidden" w="full" alignItems="stretch">
          <ScrollDragContainer mouseScroll={false} w="full">
            <HStack w="full">
              <Dropdown
                isDisabled={isLoading}
                id="size options"
                fontSize="sm"
                bg="background.surface"
                borderColor="primary"
                h={8}
                rounded="full"
                minW={180}
                enum={PageSizeOptions}
                value={currentSize || ''}
                onOptionSelection={updateSize}
              />
              <Dropdown
                isDisabled={isLoading}
                bg="background.surface"
                borderColor="primary"
                h={8}
                rounded="full"
                minW={180}
                fontSize="sm"
                placeholder="Ordenar"
                enum={RealEstateSortOptions}
                value={currentOrder || ''}
                onOptionSelection={updateOrder}
              />
              <ShareLinkModal />
            </HStack>
          </ScrollDragContainer>
        </Flex>
      </Stack>

      {!items?.length && !isLoading ? (
        <Flex
          minH={400}
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
          rounded="md"
          flexDirection="column"
          boxShadow="sm"
          bg="background.default">
          <Text fontSize="lg" color="gray">
            Lo sentimos, no se encontraron resultados.
          </Text>
          <Button variant="ghost" onClick={onReset}>
            Borrar filtros
          </Button>
        </Flex>
      ) : null}

      {!isLoading && items ? (
        showList ? (
          items.map((item) => {
            return (
              <VStack
                bg="background.default"
                rounded="md"
                overflow="hidden"
                key={item.id}
                w="full"
                alignItems="stretch"
                divider={<StackDivider />}
                gap={0}>
                <HStack alignItems="stretch" spacing={0} divider={<StackDivider />}>
                  <ImageCarousel
                    w={SEARCH_ITEM_SIZE}
                    h={SEARCH_ITEM_SIZE}
                    imgSrcs={mainMediasFactory(item.medias).map((m) => m.thumb)}
                  />
                  <RealEstateListItem
                    onClick={() => handleItemClick(item)}
                    flex={1}
                    p={2}
                    {...{ item }}
                  />
                </HStack>
                {item.highlights.length ? (
                  <SimpleGrid bg="background.default" columns={2} w="full" gap={2} py={2} px={6}>
                    {item.highlights.map((highlight, index) => (
                      <GridItem key={index} flex={1}>
                        <HStack w="full">
                          <Icon as={FaSquare} color="primary" boxSize={2} />
                          <Text fontSize="sm">{highlight}</Text>
                        </HStack>
                      </GridItem>
                    ))}
                  </SimpleGrid>
                ) : null}
              </VStack>
            )
          })
        ) : (
          <SimpleGrid columns={isMobile ? 1 : showMap ? 2 : 3} spacing={4}>
            {items.map((item) => (
              <GridItem key={item.id}>
                <RealEstateItemVertical item={item} onItemClick={handleItemClick} />
              </GridItem>
            ))}
          </SimpleGrid>
        )
      ) : null}

      {!isLoading
        ? items?.map((item) => {
            return <VStack key={item.id} w="full" alignItems="stretch" gap={0}></VStack>
          })
        : Array.from({ length: 3 }).map((_, i) => <RealEstateItemSkeleton key={i} />)}

      <Pagination
        justify="flex-end"
        alignSelf="flex-end"
        setPage={(p) => setPage(p)}
        page={page}
        {...{ totalPages }}
      />
    </Container>
  )
}

export default RealEstateList
