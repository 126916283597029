import React, { FC, useCallback, useEffect } from 'react'
import { HStack, Icon, IconButton, StackProps, Text, VStack } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { BiDotsHorizontalRounded } from 'react-icons/bi'

interface PaginationProps extends StackProps {
  totalPages: number
  page: number
  setPage: (page: number) => void
}

const Pagination: FC<PaginationProps> = ({ totalPages, page, setPage, ...props }) => {
  const edgeCount = 1
  const siblingCount = 2
  const moreCount = 3
  const range = siblingCount + edgeCount + 1

  const [minPageLimit, setMinPageLimit] = React.useState(Math.max(1, page - siblingCount))
  const [maxPageLimit, setMaxPageLimit] = React.useState(Math.min(totalPages, page + siblingCount))

  const updatePageLimits = useCallback(
    (currentPage: number) => {
      if (currentPage <= range) {
        setMinPageLimit(1)
        setMaxPageLimit(Math.min(range + 1, totalPages))
      } else if (totalPages - range < currentPage) {
        setMinPageLimit(Math.max(totalPages - range - 1, 1))
        setMaxPageLimit(totalPages)
      } else {
        setMinPageLimit(currentPage - siblingCount)
        setMaxPageLimit(Math.min(currentPage + siblingCount, totalPages))
      }
    },
    [range, totalPages]
  )

  useEffect(() => {
    updatePageLimits(page)
  }, [page, updatePageLimits])

  const handlePrev = () => {
    const newPage = Math.max(1, page - 1)
    setPage(newPage)
    updatePageLimits(newPage)
  }

  const handleNext = () => {
    const newPage = Math.min(totalPages, page + 1)
    setPage(newPage)
    updatePageLimits(newPage)
  }

  const handleMorePrev = () => {
    const newPage = Math.max(1, page - moreCount)
    setPage(newPage)
    updatePageLimits(newPage)
  }

  const handleMoreNext = () => {
    const newPage = Math.min(totalPages, page + moreCount)
    setPage(newPage)
    updatePageLimits(newPage)
  }

  return (
    <VStack w="full" mt={5} gap={1} {...props}>
      <HStack w="full" justify="flex-end">
        <IconButton
          w={8}
          h={8}
          minW="auto"
          bg="transparent"
          aria-label="previous"
          isDisabled={page === 1}
          icon={<ChevronLeftIcon boxSize={4} />}
          onClick={handlePrev}
        />

        {minPageLimit > 1 && (
          <>
            <IconButton
              w={10}
              h={10}
              minW="auto"
              aria-label="page"
              bg={page === 1 ? 'primary' : 'transparent'}
              onClick={() => setPage(1)}>
              <Text fontWeight="bold" fontSize="sm" color={page === 1 ? 'white' : 'gray.800'}>
                1
              </Text>
            </IconButton>
            <IconButton
              w={10}
              h={10}
              minW="auto"
              aria-label="more"
              bg="transparent"
              icon={<Icon as={BiDotsHorizontalRounded} boxSize={4} />}
              onClick={handleMorePrev}
            />
          </>
        )}

        {[...Array(maxPageLimit - minPageLimit + 1)].map((_, i) => {
          const pageNumber = minPageLimit + i
          return (
            <IconButton
              w={10}
              h={10}
              minW="auto"
              aria-label="page"
              key={pageNumber}
              bg={page === pageNumber ? 'primary' : 'transparent'}
              onClick={() => setPage(pageNumber)}>
              <Text
                fontWeight="bold"
                fontSize="sm"
                color={page === pageNumber ? 'white' : 'gray.800'}>
                {pageNumber}
              </Text>
            </IconButton>
          )
        })}

        {maxPageLimit < totalPages && (
          <>
            <IconButton
              w={10}
              h={10}
              minW="auto"
              aria-label="more"
              bg="transparent"
              icon={<Icon as={BiDotsHorizontalRounded} boxSize={4} />}
              onClick={handleMoreNext}
            />
            <IconButton
              w={10}
              h={10}
              minW="auto"
              aria-label="page"
              bg={page === totalPages ? 'primary' : 'transparent'}
              onClick={() => setPage(totalPages)}>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={page === totalPages ? 'white' : 'gray.800'}>
                {totalPages}
              </Text>
            </IconButton>
          </>
        )}

        <IconButton
          w={8}
          h={8}
          minW="auto"
          aria-label="next"
          bg="transparent"
          isDisabled={page === totalPages}
          icon={<ChevronRightIcon boxSize={4} />}
          onClick={handleNext}
        />
      </HStack>
    </VStack>
  )
}

export default Pagination
