export function calculatePercentage(
  dimension: string | number,
  percentage: number
): string | number | undefined {
  const decimal = percentage / 100
  if (typeof dimension === 'number') {
    return dimension * decimal
  } else {
    const unit = /px|em|rem|%/i.exec(dimension)
    if (unit) {
      const value = parseFloat(dimension)
      if (!isNaN(value)) return value * decimal + unit[0]
    }
  }
}
