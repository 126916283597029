import React from 'react'
import { ButtonGroup, ButtonGroupProps, Icon, IconButton, useBoolean } from '@chakra-ui/react'
import { FaThList } from 'react-icons/fa'
import { BsFillGridFill } from 'react-icons/bs'

type DisplayType = 'list' | 'grid'

interface ToggleDisplayButtonProps extends ButtonGroupProps {
  onUpdate?: (displayType: DisplayType) => void
}

const ToggleDisplayButton: React.FC<ToggleDisplayButtonProps> = ({ onUpdate, ...props }) => {
  const [isList, setIsList] = useBoolean(true)

  const handleListToggle = () => {
    setIsList.on()
    onUpdate?.('list')
  }

  const handleGridToggle = () => {
    setIsList.off()
    onUpdate?.('grid')
  }

  return (
    <ButtonGroup size="sm" isAttached variant="outline" colorScheme="none" {...props}>
      <IconButton
        aria-label="lista"
        minW={12}
        bg={isList ? 'background.surface' : 'transparent'}
        isActive={isList}
        icon={<Icon boxSize={4} as={FaThList} color={isList ? 'primary.500' : 'gray.300'} />}
        onClick={handleListToggle}
      />
      <IconButton
        aria-label="grid"
        minW={12}
        bg={!isList ? 'gray.300' : 'transparent'}
        isActive={!isList}
        icon={<Icon boxSize={4} as={BsFillGridFill} color={!isList ? 'primary.500' : 'gray.300'} />}
        onClick={handleGridToggle}
      />
    </ButtonGroup>
  )
}

export default ToggleDisplayButton
